.header-carousel .carousel-image {
    height: 90vh; /* Adjust height as needed */
    object-fit: cover;
  }
  
  .carousel-caption {
    background: rgba(0, 0, 0, 0.6); /* Dark overlay for text visibility */
    padding: 1rem;
    border-radius: 8px;
  }
  
  .carousel-caption h2 {
    font-size: 1.75rem;
    font-weight: bold;
    color: #fff;
  }
  
  .badge {
    font-size: 0.85rem;
    padding: 0.3rem 0.6rem;
  }
  
  @media (max-width: 768px) {
    .carousel-caption h2 {
      font-size: 1.25rem;
    }
    .header-carousel .carousel-image {
      height: 60vh; /* Adjust height as needed */
      object-fit: cover;
    }
  }
  