/* AllArticles.css */

/* Container for the entire component */
.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Title Styling */
h2.text-center {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

/* Filter dropdown container */
.filter-container {
  margin-bottom: 20px;
}

.filter-container label {
  font-size: 1.1rem;
  font-weight: 600;
}

/* Card Styles */
.article-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.article-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
  transition: all 0.3s ease-in-out;
}

/* Card image styles */
.article-image {
  height: 200px;
  object-fit: cover;
}

/* Card Body Styles */
.card-body {
  padding: 20px;
  background-color: #fff;
}

.article-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.article-content {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

/* Button Styling */
.read-more-btn {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.read-more-btn:hover {
  background-color: #0056b3;
  text-decoration: none;
}

.read-more-btn:focus {
  outline: none;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .card-body {
    padding: 15px;
  }
  
  .article-title {
    font-size: 1.15rem;
  }

  .article-content {
    font-size: 0.95rem;
  }
}

@media (max-width: 576px) {
  .article-card {
    margin-bottom: 20px;
  }
  .read-more-btn {
    width: 100%;
  }
}
